import React from "react"
import { Layout, SEO, Section, Container } from "../components"

const cookiesPolicy = ({ location }) => {
  return (
    <Layout location={location} title="LightVille Legal" subTitle="Legal">
      <SEO title="LightVille Legal" description="LightVille's Legal page" />
      <Section>
        <Container width="narrow">
          <p>
            The information provided on this website is for general guidance on
            concerned matters and does not depict professional advice on
            particular subjects. We disclaim any liability for any loss suffered
            or incurred in the process of the application or use of the contents
            on our website. Information provided on our website should not be
            substituted for professional management service or substituted for
            professional advisors. For any of such professional service
            required, please contact any of the professionals at LightVille
            Consulting.
          </p>

          <p>
            All information on this website is provided “AS IS” with no
            guarantee of accuracy, timeliness, exhaustiveness or of the results
            derived from the use or implementation of this information and
            without any warranty of any kind, express or implied, including but
            not limited to warranties of performance, merchantability and
            fitness for purpose. We hereby disclaim all warranties.
          </p>

          <p>
            The LightVille name, logos and marks used on this website are
            registered trademarks of LightVille Consulting Services Ltd. The
            marks shall not be used without express prior permission from
            LightVille. Any unauthorized use of these marks is extremely
            prohibited and shall have the full extent of the law brought on such
            one.
          </p>

          <p>
            Contents on this website may be copyrighted, proprietary and subject
            to intellectual property or other rights. Any unauthorized use of
            any materials or contents on the site shall violate copyright,
            trademark and other applicable laws. Your use of our website is at
            your own risk. We will therefore not be liable for any loss
            resulting from your usage.
          </p>

          <p>
            Links on this website may be controlled by third parties over whom
            we have no control, including without limitation, those maintained
            by other entities within LightVille network or individual personnel
            of such entities. Without limiting any of the foregoing, we make no
            express or implied representations or warranties whatsoever,
            regarding such links or websites, resources and tools. Any link to
            such website resources and tools shall not be inferred as an
            endorsement of them by LightVille.{" "}
          </p>
        </Container>
      </Section>
    </Layout>
  )
}

export default cookiesPolicy
